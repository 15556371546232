import { AvailableTypes } from '@/components/editor/config/types';

export const entitiesOptions = {
    forms: {
        name: {
            type: AvailableTypes.string,
            title: 'Имя'
        },
        form: {
            type: AvailableTypes.form
        },
        modal: {
            type: AvailableTypes.select,
            title: 'Модальное окно',
            values: {
                type: AvailableTypes.apiSource,
                path: '/entities/modals/',
                method: 'get',
                conditions: {},
                mappings: {
                    value: '_id',
                    text: 'options.name'
                }
            }
        }
    },
    blockpresets: {
        name: {
            type: AvailableTypes.string,
            title: 'Имя'
        },
        component: {
            type: AvailableTypes.string,
            title: 'Компонент'
        },
        preset: {
            type: AvailableTypes.string,
            hidden: true
        },
        pages: {
            type: AvailableTypes.pages
        }
    },
    users: {
        active: {
            type: AvailableTypes.boolean,
            title: 'Активность'
        },
        login: {
            type: AvailableTypes.string,
            title: 'Логин'
        },
        email: {
            type: AvailableTypes.string,
            title: 'Email'
        },
        name: {
            type: AvailableTypes.string,
            title: 'Имя'
        },
        password: {
            type: AvailableTypes.password,
            title: 'Пароль'
        },
        role: {
            type: AvailableTypes.select,
            title: 'Роль',
            values: [
                {
                    text: 'Админ',
                    value: 0,
                    selected: true
                },
                {
                    text: 'Редактор',
                    value: 1
                },
                {
                    text: 'Менеджер',
                    value: 2
                }
            ]
        }
    },
    modals: {
        name: {
            type: AvailableTypes.string,
            title: 'Имя'
        },
        image: {
            type: AvailableTypes.image,
            title: 'Изображение'
        },
        text: {
            type: AvailableTypes.string,
            title: 'Текст'
        }
    },
    colors: {
        name: {
            title: 'Наименование',
            type: AvailableTypes.string
        },
        description: {
            title: 'Описание',
            type: AvailableTypes.string
        },
        colors: {
            type: AvailableTypes.arrayOfType,
            title: 'Цвета',
            item: {
                type: AvailableTypes.color
            }
        }
    },
    redirects: {
        name: {
            title: 'Имя группы',
            type: AvailableTypes.string
        },
        items: {
            type: AvailableTypes.array,
            item: {
                from: {
                    title: 'Откуда',
                    type: AvailableTypes.string
                },
                to: {
                    title: 'Куда',
                    type: AvailableTypes.string
                },
                code: {
                    title: 'Код',
                    type: AvailableTypes.string,
                    default: '301'
                }
            }
        }
    },
    tags: {
        name: {
            title: 'Название группы',
            type: AvailableTypes.string
        },
        tags: {
            type: AvailableTypes.array,
            title: 'Теги',
            item: {
                name: {
                    title: 'Название тега',
                    type: AvailableTypes.textarea
                },
                slug: {
                    title: 'Slug',
                    type: AvailableTypes.textarea
                }
            }
        }
    }
};
