
    import { Component, Emit, Prop, Vue, Watch } from 'nuxt-property-decorator';
    import { Editor, EditorMenuBar, EditorContent, EditorMenuBubble } from 'tiptap';
    import {
        ListItem,
        Bold,
        Heading,
        Blockquote,
        HardBreak,
        OrderedList,
        BulletList,
        Italic,
        History,
        Table,
        TableHeader,
        TableCell,
        TableRow
    } from 'tiptap-extensions';
    import FontSize from '../editor/extensions/FontSize';
    import LineHeight from '../editor/extensions/LineHeight';
    import OptionsFieldsEditorMenu from '@/components/editor/options/editor/OptionsFieldsEditorMenu.vue';
    import CustomizationLink from '../editor/extensions/CustomizationLink';
    import StringBackColor from '../editor/extensions/StringBackColor';
    import BgPalette from '../editor/extensions/BgPalette';
    import Color from '../editor/extensions/Color';
    import FontFamily from '../editor/extensions/FontFamily';

    @Component({
        components: {
            OptionsFieldsEditorMenu,
            EditorContent,
            EditorMenuBar,
            EditorMenuBubble
        }
    })
    export default class OptionsFieldsEditor extends Vue {
        @Prop() value;
        @Prop() inline: boolean;

        @Watch('value', { immediate: true })
        changeValue(newVal, oldVal) {
            if (this.editorConfig && !this.selfChange) {
                this.editorConfig.setContent(newVal);
            } else {
                this.selfChange = false;
            }
        }

        selfChange = false;

        showBubble = false;
        linkMenuIsActive = false;

        editorConfig = null as any;

        beforeMount() {
            this.editorConfig = new Editor({
                extensions: [
                    new Bold(),
                    new Heading(),
                    new ListItem(),
                    new BulletList(),
                    new OrderedList(),
                    new Italic(),
                    new HardBreak(),
                    new Blockquote(),
                    new History(),
                    new Table(),
                    new TableCell(),
                    new TableHeader(),
                    new TableRow(),
                    new FontSize(),
                    new LineHeight(),
                    new CustomizationLink(),
                    new StringBackColor(),
                    new BgPalette(),
                    new Color(),
                    new FontFamily()
                ],
                content: this.value,
                onUpdate: ({ getHTML }) => {
                    let html = getHTML();
                    if (html === '<p></p>') {
                        html = '';
                    }
                    this.$emit('input', html);
                    this.selfChange = true;
                    // get new content on update
                    this.setValue(html);
                },
                onFocus: () => {
                    this.showBubble = true;
                },
                onBlur: () => {
                    this.showBubble = false;
                }
            });
        }

        @Emit('changeValue')
        setValue(value) {
            return value;
        }
    }
