import { Mark } from 'tiptap';
import { updateMark, markInputRule, removeMark } from 'tiptap-commands';

export default class StringBackColor extends Mark {
    get name() {
        return 'stringBackColor';
    }

    get schema() {
        return {
            attrs: {
                stringBackColor: {
                    default: '#fff'
                }
            },
            parseDOM: [
                {
                    style: 'background-color',
                    getAttrs: mark => ({ stringBackColor: mark })
                }
            ],
            toDOM: ({ attrs: { stringBackColor } }) => [
                'span',
                {
                    style: `background-color: ${
                        new RegExp('^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$').test(
                            stringBackColor
                        )
                            ? stringBackColor
                            : ''
                    }`
                },
                0
            ]
        };
    }

    commands({ type }) {
        return attrs => {
            if (attrs.stringBackColor) {
                return updateMark(type, attrs);
            }
            return removeMark(type);
        };
    }

    inputRules({ type }) {
        return [markInputRule(/(?:\*\*|__)([^*_]+)(?:\*\*|__)$/, type)];
    }
}
