import { Mark } from 'tiptap';
import { toggleMark } from 'tiptap-commands';

export default class Bold extends Mark {
    get name() {
        return 'fontFamily';
    }

    get schema() {
        return {
            parseDOM: [
                {
                    style: 'font-family',
                    getAttrs: val => ({ fontFamily: val })
                }
            ],
            toDOM: ({ attrs: { fontFamily } }) => [
                'span',
                {
                    style: `font-family: ${fontFamily || 'Five years later'}`
                },
                0
            ]
        };
    }

    commands({ type }) {
        return () => toggleMark(type);
    }
}
