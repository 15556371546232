
    import { Component, Prop, Emit } from 'nuxt-property-decorator';
    import PaletteMixin from '@/mixins/paletteMixin';

    @Component
    export default class EditorCommandWithInput extends PaletteMixin {
        @Prop({ required: true }) markType: string;
        @Prop({ required: true }) attribute: string = '';
        @Prop({ required: true }) isActive: boolean;
        @Prop() iconName: string;
        @Prop({ default: 'text' }) inputType: string;
        @Prop({ default: 1 }) numberInputStep: number;
        @Prop({ required: true }) getMarkAttrs;
        @Prop() placeholder: string;

        showMenu = false;
        value = '';
        linkAttributes = {
            ['rel']: 'noopener noreferrer nofollow',
            ['target']: '_self',
            [this.attribute]: this.value
        };

        openMenu() {
            this.showMenu = true;

            const newVal = this.getMarkAttrs(this.markType)[this.attribute];

            if (
                this.getMarkAttrs(this.markType)[this.attribute] &&
                this.markType === 'fontSize'
            ) {
                this.value = newVal.replace('px', '');
            } else {
                this.value = newVal;
            }

            this.$nextTick(() => {
                this.$refs.input && (<HTMLInputElement>this.$refs.input).focus();
            });
        }

        @Emit()
        changeRel() {
            this.showMenu = false;
            this.linkAttributes['rel'] =
                this.linkAttributes['rel'] === 'noopener noreferrer nofollow'
                    ? 'noopener noreferrer'
                    : 'noopener noreferrer nofollow';
            return this.linkAttributes;
        }

        @Emit()
        changeTarget() {
            this.showMenu = false;
            this.linkAttributes['target'] =
                this.linkAttributes['target'] === '_self' ? '_blank' : '_self';
            return this.linkAttributes;
        }

        @Emit()
        changeValue<T>(val: T) {
            this.showMenu = false;
            return { [this.attribute]: val };
        }
    }
