export const optionsDefault = {
    CardList: [
        {
            title: 'Рассчитываем',
            text:
                'Присылайте ваш запрос на тиражи от 100 штук с прикрепленным макетом нанесения. Ответим \n' +
                'в течение 5–30 минут.',
            img: {
                src: '/block-default/CardList/stages-of-work-1.png'
            }
        },
        {
            title: 'Рассчитываем',
            text:
                'Присылайте ваш запрос на тиражи от 100 штук с прикрепленным макетом нанесения. Ответим \n' +
                'в течение 5–30 минут.',
            img: {
                src: '/block-default/CardList/stages-of-work-2.png'
            }
        },
        {
            title: 'Рассчитываем',
            text:
                'Присылайте ваш запрос на тиражи от 100 штук с прикрепленным макетом нанесения. Ответим \n' +
                'в течение 5–30 минут.',
            img: {
                src: '/block-default/CardList/stages-of-work-3.png'
            }
        },
        {
            title: 'Рассчитываем',
            text:
                'Присылайте ваш запрос на тиражи от 100 штук с прикрепленным макетом нанесения. Ответим \n' +
                'в течение 5–30 минут.',
            img: {
                src: '/block-default/CardList/stages-of-work-4.png'
            }
        }
    ],
    TabsSlider: [
        {
            tabTitle: 'Услуги и ритейл',
            text:
                'Корпоративная одежда сделает заметными сотрудников в торговом зале, создаст правильный образ компании и подчеркнет фирменный стиль',
            buttonName: 'Наши работы >',
            img: {
                src: '/block-default/TabsSlider/3333.png'
            },
            sliderImg: [
                {
                    img: {
                        src: '/block-default/TabsSlider/Mask Group.png'
                    }
                }
            ],
            list: [
                {
                    listHeader: 'Уже с нами',
                    listContentElement: [
                        {
                            itemName: 'Окна Аттик'
                        },
                        {
                            itemName: 'Мосигра'
                        },
                        {
                            itemName: 'Билайн'
                        },
                        {
                            itemName: 'Pandora'
                        },
                        {
                            itemName: 'Гольфстрим'
                        },
                        {
                            itemName: 'Купи чехол'
                        },
                        {
                            itemName: 'Chop-chop'
                        }
                    ]
                },
                {
                    listHeader: 'Продукция',
                    listContentElement: [
                        {
                            itemName: 'Поло'
                        },
                        {
                            itemName: 'Толстовки'
                        },
                        {
                            itemName: 'Футболки'
                        },
                        {
                            itemName: 'Фартуки'
                        },
                        {
                            itemName: 'Пледы'
                        },
                        {
                            itemName: 'Платки'
                        }
                    ]
                }
            ]
        }
    ],
    TriggerBlockWithSixPicture: [
        {
            img: {
                src: '/block-default/TriggerBlockWithSixPicture/Rectangle 17.png'
            }
        },
        {
            img: {
                src: '/block-default/TriggerBlockWithSixPicture/Rectangle 18.png'
            }
        },
        {
            img: {
                src: '/block-default/TriggerBlockWithSixPicture/Rectangle 19.png'
            }
        },
        {
            img: {
                src: '/block-default/TriggerBlockWithSixPicture/Rectangle 20.png'
            }
        },
        {
            img: {
                src: '/block-default/TriggerBlockWithSixPicture/Rectangle 21.png'
            }
        },
        {
            img: {
                src: '/block-default/TriggerBlockWithSixPicture/Rectangle 22.png'
            }
        }
    ],
    Models: [
        {
            title: 'классические',
            images: [
                {
                    src: '/block-default/TabsWithImagesAndText/t-shirt-models-1.jpg'
                },
                {
                    src: '/block-default/TabsWithImagesAndText/t-shirt-models-2.jpg'
                }
            ],
            text:
                'Футболки оверсайз — это такие футболки, в которых комфортно всем в любых условиях.'
        }
    ],
    QuestionAnswer: [
        {
            title: 'информация',
            content: {
                items: [
                    {
                        question: 'Есть ли карта цветов материала?',
                        answer:
                            'На сайте размещены базовые цвета ссылка, которые обычно есть \n' +
                            'в наличии.\n' +
                            '\n' +
                            'Если вам нужен конкретный цвет, пришлите нам его фото или номер \n' +
                            'по пантону ссылка. Мы подберем ближайшие по цвету варианты \n' +
                            'тканей от наших поставщиков. \n' +
                            '\n' +
                            'Если вы не знаете точно, какой цвет вам нужен и хотите посмотреть материалы вживую, можно подъехать к нам в офис.\n' +
                            '\n' +
                            'Когда нужен цвет по пантону, возможно покрасить ткань на заказ, \n' +
                            'но при этом минимальный тираж футболок будет от 1000 шт. \n' +
                            'и сроки 1,5–2 месяца.'
                    }
                ]
            }
        }
    ],
    AdditionalOptions: [
        {
            title: 'Фирменный паттерн',
            text:
                'Обычно поставщик держит на складе основную палитру цветов. Если в ней нет нужного вам цвета сделайте запрос.  Обычно поставщик держит на складе основную палитру цветов. Если в ней нет нужного вам цвета сделайте запрос. Обычно поставщик держит на складе основную палитру цветов. Если в ней нет нужного вам цвета сделайте запрос.',
            images: [
                {
                    src: '/block-default/AdditionalOptions/additional-options-1.jpg',
                    alt: '',
                    title: ''
                }
            ],
            link: '/',
            targetBlank: false
        },
        {
            title: 'Цветные вставки',
            text:
                'Обычно поставщик держит на складе основную палитру цветов. Если в ней нет нужного вам цвета сделайте запрос.  Обычно поставщик держит на складе основную палитру цветов. Если в ней нет нужного вам цвета сделайте запрос. Обычно поставщик держит на складе основную палитру цветов. Если в ней нет нужного вам цвета сделайте запрос.',
            images: [
                {
                    src: '/block-default/AdditionalOptions/additional-options-2.jpg',
                    alt: '',
                    title: ''
                }
            ],
            link: '/',
            targetBlank: false
        },
        {
            title: 'Красивая обтачка',
            text:
                'Обычно поставщик держит на складе основную палитру цветов. Если в ней нет нужного вам цвета сделайте запрос.  Обычно поставщик держит на складе основную палитру цветов. Если в ней нет нужного вам цвета сделайте запрос. Обычно поставщик держит на складе основную палитру цветов. Если в ней нет нужного вам цвета сделайте запрос.',
            images: [
                {
                    src: '/block-default/AdditionalOptions/additional-options-3.jpg',
                    alt: '',
                    title: ''
                }
            ],
            link: '/',
            targetBlank: false
        },
        {
            title: 'Рукав реглан',
            text:
                'Обычно поставщик держит на складе основную палитру цветов. Если в ней нет нужного вам цвета сделайте запрос.  Обычно поставщик держит на складе основную палитру цветов. Если в ней нет нужного вам цвета сделайте запрос. Обычно поставщик держит на складе основную палитру цветов. Если в ней нет нужного вам цвета сделайте запрос.',
            images: [
                {
                    src: '/block-default/AdditionalOptions/additional-options-4.jpg',
                    alt: '',
                    title: ''
                }
            ],
            link: '/',
            targetBlank: false
        },
        {
            title: 'Декоративные отстрочки',
            text:
                'Обычно поставщик держит на складе основную палитру цветов. Если в ней нет нужного вам цвета сделайте запрос.  Обычно поставщик держит на складе основную палитру цветов. Если в ней нет нужного вам цвета сделайте запрос. Обычно поставщик держит на складе основную палитру цветов. Если в ней нет нужного вам цвета сделайте запрос.',
            images: [
                {
                    src: '/block-default/AdditionalOptions/additional-options-5.jpg',
                    alt: '',
                    title: ''
                }
            ],
            link: '/',
            targetBlank: false
        }
    ],
    BagsKnowPrice: {
        head: '<p>Узнать цены на сумки</p>',
        text:
            '<p>Мы не пишем цены так как это слишком громоздко. Каждый наш заказ уникален, в нем много переменных: стоимость материала, индивидуальные размеры, способ брендирования, тираж и сроки.&nbsp;</p>',
        leftImg: {
            src: '/block-default/TriggerBlockImages/bags-size1.png',
            alt: '',
            title: ''
        },
        rightImg: {
            src: '/block-default/TriggerBlockImages/bags-size1.png',
            alt: '',
            title: ''
        },
        buttonText: 'Заказать расчет по параметрам',
        additionalClass: '',
        rotateImage: false
    },

    BagsYourSize: {
        head: '<p>Сумки по вашим</p><p>размерам</p>',
        text:
            '<p>Размещении заказа возникают следующие проблемы: вы не знаете размерный ряд или он слишком большой, или нужны уникальные футболки для конкретной группы людей. &nbsp;</p>',
        leftImg: {
            src: '/block-default/TriggerBlockImages/bags-size.png',
            alt: '',
            title: ''
        },
        rightImg: {
            src: '/block-default/TriggerBlockImages/bags-size1.png',
            alt: '',
            title: ''
        },
        buttonText: 'Заказать',
        additionalClass: '',
        rotateImage: true
    },
    TableHeaders: [
        {
            text: ' Производитель'
        },
        {
            text: '100 шт.'
        },
        {
            text: '300 шт.'
        },
        {
            text: '1000 шт.'
        }
    ],

    TableRow: [
        {
            value: 'Турция, 230 гр.'
        },
        {
            value: '-'
        },
        {
            value: '65'
        },
        {
            value: '60'
        }
    ],

    TabsWithTable: [
        {
            title: '130×170 см',
            text:
                '<p>Про цены размещения заказа возникают следующие проблемы: вы не знаете размерный ряд или он слишком большой, или нужны уникальные футболки для конкретной группы людей.</p>',
            buttonText: 'Заказать расчет',
            table: {
                name: 'Table',
                active: true,
                options: {
                    headers: [
                        {
                            text: ' Производитель'
                        },
                        {
                            text: '100 шт.'
                        },
                        {
                            text: '300 шт.'
                        },
                        {
                            text: '1000 шт.'
                        }
                    ],
                    rows: [
                        {
                            row: [
                                {
                                    value: 'Турция, 230 гр.'
                                },
                                {
                                    value: '-'
                                },
                                {
                                    value: '65'
                                },
                                {
                                    value: '60'
                                }
                            ]
                        },
                        {
                            row: [
                                {
                                    value: 'Китай, 180 гр.'
                                },
                                {
                                    value: '120'
                                },
                                {
                                    value: '30'
                                },
                                {
                                    value: '65'
                                }
                            ]
                        },
                        {
                            row: [
                                {
                                    value: 'Китай, 250 гр.'
                                },
                                {
                                    value: '-'
                                },
                                {
                                    value: '65'
                                },
                                {
                                    value: '60'
                                }
                            ]
                        },
                        {
                            row: [
                                {
                                    value: 'Китай, 300 гр.'
                                },
                                {
                                    value: '120'
                                },
                                {
                                    value: '30'
                                },
                                {
                                    value: '65'
                                }
                            ]
                        }
                    ],
                    tableClass: ''
                }
            }
        },
        {
            title: '145×200 см',
            text:
                '<p>Про цены размещения заказа возникают следующие проблемы: вы не знаете размерный ряд или он слишком большой, или нужны уникальные футболки для конкретной группы людей.</p>',
            buttonText: 'Заказать расчет',
            table: {
                name: '',
                active: true,
                options: {
                    headers: [
                        {
                            text: ''
                        }
                    ],
                    rows: [
                        {
                            row: [
                                {
                                    value: ''
                                }
                            ]
                        }
                    ],
                    tableClass: ''
                }
            }
        },
        {
            title: '175×220 СМ',
            text:
                '<p>Про цены размещения заказа возникают следующие проблемы: вы не знаете размерный ряд или он слишком большой, или нужны уникальные футболки для конкретной группы людей.</p>',
            buttonText: 'Заказать расчет',
            table: {
                name: '',
                active: true,
                options: {
                    headers: [
                        {
                            text: ''
                        }
                    ],
                    rows: [
                        {
                            row: [
                                {
                                    value: ''
                                }
                            ]
                        }
                    ],
                    tableClass: ''
                }
            }
        }
    ],
    Products: [
        {
            img: {
                src: '/block-default/Products/2.png',
                alt: '',
                title: ''
            },
            title: 'Свитшоты',
            href: '',
            targetBlank: false
        },
        {
            img: {
                src: '/block-default/Products/3.png',
                alt: '',
                title: ''
            },
            title: 'Сумки',
            href: '',
            targetBlank: false
        },
        {
            img: {
                src: '/block-default/Products/4.png',
                alt: '',
                title: ''
            },
            title: 'Шарфы',
            href: '',
            targetBlank: false
        },
        {
            img: {
                src: '/block-default/Products/1.png',
                alt: '',
                title: ''
            },
            title: 'Панамы',
            href: '',
            targetBlank: false
        }
    ],
    WorksList: [
        {
            img: {
                src: '/block-default/WorksList/works_1.png',
                alt: '',
                title: ''
            },
            title: 'Clinique',
            href: '#',
            targetBlank: false,
            text:
                '1500 свитшотов с мерчем компании для акции на 14 февраля в сетевых магазинах косметики '
        },
        {
            img: {
                src: '/block-default/WorksList/works_2.png',
                alt: '',
                title: ''
            },
            title: 'ФК Локомотив',
            href: '#',
            targetBlank: false,
            text: 'Регулярные партии свитшотов для продажи в фирменных магазинах '
        },
        {
            img: {
                src: '/block-default/WorksList/works_3.png',
                alt: '',
                title: ''
            },
            title: 'Сеть барбершопов Chop-Chop',
            href: '#',
            targetBlank: false,
            text: '200 свитшотов для подарков сотрудникам'
        },
        {
            img: {
                src: '/block-default/WorksList/works_1.png',
                alt: '',
                title: ''
            },
            title: 'Фонд Валерия Гергиева',
            href: '#',
            targetBlank: false,
            text: 'Ежегодно 1500 свитшотов для Московского Пасхального фестиваля '
        },
        {
            img: {
                src: '/block-default/WorksList/works_2.png',
                alt: '',
                title: ''
            },
            title: 'Фонд Валерия Гергиева',
            href: '#',
            targetBlank: false,
            text: 'Ежегодно 1500 свитшотов для Московского Пасхального фестиваля '
        },
        {
            img: {
                src: '/block-default/WorksList/works_3.png',
                alt: '',
                title: ''
            },
            title: 'Фонд Валерия Гергиева',
            href: '#',
            targetBlank: false,
            text: 'Ежегодно 1500 свитшотов для Московского Пасхального фестиваля'
        }
    ],
    SliderBlock: [
        {
            img: {
                src: '/block-default/SliderBlock/2.png',
                alt: '',
                title: ''
            },
            title: 'Russian ballet',
            href: '/',
            text:
                'Печать производится наспециальной бумаге, а затер переносится на ткань термопереносом.'
        },
        {
            img: {
                src: '/block-default/SliderBlock/1.png',
                alt: '',
                title: ''
            },
            title: 'WD',
            href: '/',
            text:
                'Трафаретная печать. Для каждого цвета готовится отдельный трафарет. Краска наносится последовательно.'
        },
        {
            img: {
                src: '/block-default/SliderBlock/5.png',
                alt: '',
                title: ''
            },
            title: 'Униформа',
            href: '/',
            text:
                'Печать производится наспециальной бумаге, а затер переносится на ткань термопереносом.'
        },
        {
            img: {
                src: '/block-default/SliderBlock/4.png',
                alt: '',
                title: ''
            },
            title: 'Для мужчин',
            href: '/',
            text:
                'Печать производится наспециальной бумаге, а затер переносится на ткань термопереносом.'
        }
    ],
    ExpertiseAndArticles: [
        {
            img: {
                src: '/block-default/ExpertiseAndArticles/1.png',
                alt: '',
                title: ''
            },
            title: 'Шедевры агиттекстиля',
            href: '',
            hrefTargetBlank: false,
            catalog: 'печать',
            catalogHref: '',
            catalogHrefTargetBlank: false,
            text:
                'Ждем вас возникают следующие проблемы: вы не знаете размерный ряд или он слишком большой, или нужны уникальные футболки для конкретной группы людей. '
        },
        {
            img: {
                src: '/block-default/ExpertiseAndArticles/2.png',
                alt: '',
                title: ''
            },
            title: 'Секреты нашего производства',
            href: '',
            hrefTargetBlank: false,
            catalog: 'информация',
            catalogHref: '',
            catalogHrefTargetBlank: false,
            text:
                'Ждем вас возникают следующие проблемы: вы не знаете размерный ряд или он слишком большой, или нужны уникальные футболки для конкретной группы людей. '
        },
        {
            img: {
                src: '/block-default/ExpertiseAndArticles/3.png',
                alt: '',
                title: ''
            },
            title: 'Какой вид нанесения выбрать?',
            href: '',
            hrefTargetBlank: false,
            catalog: 'совет',
            catalogHref: '',
            catalogHrefTargetBlank: false,
            text:
                'Ждем вас возникают следующие проблемы: вы не знаете размерный ряд или он слишком большой, или нужны уникальные футболки для конкретной группы людей. '
        }
    ],
    CardsInTwoColumns: [
        {
            title: 'Припарковаться',
            text:
                'Последний вагон из центра, по переходу налево. Проехать до остановки «Косинская фабрика» на автобусах 773, 79 или 723.',
            img: {
                src: '/block-default/CardsInTwoColumns/111623.jpg',
                alt: '',
                title: ''
            }
        },
        {
            title: 'Выписать пропуск',
            text:
                'Последний вагон из центра, по переходу налево. Проехать до остановки «Косинская фабрика» на автобусах 773, 79 или 723.',
            img: {
                src: '/block-default/CardsInTwoColumns/111627.jpg',
                alt: '',
                title: ''
            }
        },
        {
            title: 'Заехать',
            text:
                'Последний вагон из центра, по переходу налево. Проехать до остановки «Косинская фабрика» на автобусах 773, 79 или 723.',
            img: {
                src: '/block-default/CardsInTwoColumns/111628.jpg',
                alt: '',
                title: ''
            }
        },
        {
            title: 'Проехать по территории',
            text:
                'Последний вагон из центра, по переходу налево. Проехать до остановки «Косинская фабрика» на автобусах 773, 79 или 723.',
            img: {
                src: '/block-default/CardsInTwoColumns/111629.jpg',
                alt: '',
                title: ''
            }
        }
    ],
    BlogThreeColumnsTable: {
        columnsHeader: [
            {
                text: 'Производство'
            },
            {
                text: 'Рекламное агенство'
            }
        ],
        rows: [
            {
                firstColumn: 'Скорость обратной связи',
                secondColumn:
                    'Зависит от культуры общения с клиентами в конкретной организации. В рекламном агентстве больше шансов получить быстрый ответ, у производителя ответ более компетентный',
                thirdColumn: ''
            },
            {
                firstColumn: 'Размещение комплексного заказа',
                secondColumn: 'Узкий спектр или полное отсутствие дополнительных услуг',
                thirdColumn: 'Специалисты по размещению комплексных заказов'
            },
            {
                firstColumn: 'Дизайн',
                secondColumn: 'Не оказывает услугу',
                thirdColumn:
                    'Могут адаптировать готовый дизайн под новую форму или сверстать макет'
            },
            {
                firstColumn: 'Мелкие заказы и личные пожелания заказчика',
                secondColumn:
                    'Ручные работы, закупка материалов, фурнитуры, подготовительный этап по нанесению занимают много времени. Запускать производство при небольших тиражах нерентабельно, но для постоянных клиентов — зачастую рекламных агентств — производители делают исключения',
                thirdColumn:
                    'Рекламные агентства за счет наработанных связей с подрядчиками могут разместить такие заказы на производстве. Цена при этом бывает высокой'
            },
            {
                firstColumn: 'Шоу-рум, образцы материалов',
                secondColumn:
                    'Образцы работ и материалов могут быть у обоих исполнителей. Это зависит от подхода компании к работе и стремления к развитию. Шоу-рум чаще есть у рекламных агентств. Их приоритет - сервис, поэтому важно показать товар в ассортименте',
                thirdColumn: ''
            },
            {
                firstColumn: 'Технологии',
                secondColumn:
                    'Представители производства знают технологии. Предлагают подходящие варианты выполнения заказа уже в начале дискуссии',
                thirdColumn:
                    'Сотрудники рекламных агентств не всегда могут предложить оптимальный вариант'
            },
            {
                firstColumn: 'Изготовление образца',
                secondColumn:
                    'Изготовление образцов нерентабельно для производства, как и мелкие заказы, поэтому такую работу выполняют либо при больших тиражах, либо подоговоренности с постоянным клиентом (часто это рекламное агентство)',
                thirdColumn:
                    'Зависят от компаний-подрядчиков: образец сделают, если согласится производитель. Результат зависит от уровня коммуникабельности.'
            },
            {
                firstColumn: 'Контроль качества и сроки',
                secondColumn:
                    'Есть необходимые мощности, которые помогают вытянуть даже «горящий» заказ. Контроль качества изготовления заказа на каждом этапе',
                thirdColumn: 'Нет такой возможности'
            },
            {
                firstColumn: 'Цена и способ оплаты',
                secondColumn:
                    'Стоимость самого товара ниже, чем у агентств. Но если учесть затраты на поиск производителя, возможные риски и дальнейшее общение, цена выйдет как у рекламных агентств и даже выше. Работают только по предоплате, как правило по УСН (упрощенная система налогообложения)',
                thirdColumn:
                    'Удобнее способ оплаты: возможны постоплата, отсрочка платежа. Работают по ОСН — общая система налогообложения'
            },
            {
                firstColumn: 'Расположение офиса',
                secondColumn:
                    'У рекламных агентств удобное местоположение ближе к центру, у производителей чаще наоборот',
                thirdColumn: ''
            }
        ]
    },
    BlogQuotes: [
        {
            image: {
                src: '/block-default/BlogQuotes/mc-queen.jpeg',
                alt: '',
                title: ''
            },
            author: 'Ли Александр Маккуин',
            profession: 'английский дизайнер модной одежды',
            quoteText:
                '«Для кого вы будете шить. Будет ли это концерт для подростков, сувениры для спортсменов или люди всех возрастов и комплекций – эта информация поможет вам наиболее верно подобрать лекала.»'
        },
        {
            image: {
                src: '/block-default/BlogQuotes/wang.jpg',
                alt: '',
                title: ''
            },
            author: 'Александр Вэнг',
            profession:
                'американский дизайнер одежды, бывший креативный директор французского модного дома Balenciaga',
            quoteText:
                '«Когда раскрой делает один подрядчик, а печатает другой, детали разных размеров могут перепутать. Когда придет время сшивать готовые изделия, разобрать детали по размерам будет очень сложно.»'
        }
    ],
    ContactsMaps: [
        {
            route: 'На машине, заезд с внешней стороны МКАД',
            description:
                'Последний вагон из центра, по переходу налево. Проехать до остановки «Косинская фабрика» на автобусах 773, 79 или 723.',
            mapLink:
                'https://yandex.ru/map-widget/v1/?um=constructor%3Aa82cfb37949a9606a9ada2fc32583e430b2f94d2ce15ac2d0301b998ef15828f&amp;source=constructor'
        },
        {
            route: 'На машине, заезд с внутренней стороны МКАД',
            description:
                'Последний вагон из центра, по переходу налево. Проехать до остановки «Косинская фабрика» на автобусах 773, 79 или 723.',
            mapLink:
                'https://yandex.ru/map-widget/v1/?um=constructor%3A8bfbc912425170444135430e2789e3e91ed883be74dae1b140530fb8a0efa828&amp;source=constructor'
        },
        {
            route: 'Пешком, от метро Новокосино',
            description:
                'Последний вагон из центра, по переходу налево. Проехать до остановки «Косинская фабрика» на автобусах 773, 79 или 723.',
            mapLink:
                'https://yandex.ru/map-widget/v1/?um=constructor%3Adaa07e52ef8caa34d295b6b63a87f3e17d26128c32a15dedc2ad102b297906cf&amp;source=constructor'
        },
        {
            route: 'Пешком от метро Выхино',
            description:
                'Последний вагон из центра, по переходу налево. Проехать до остановки «Косинская фабрика» на автобусах 773, 79 или 723.',
            mapLink:
                'https://yandex.ru/map-widget/v1/?um=constructor%3A63462b3e6d3e4f5c77db2a083176ec56ed38c34d640650ce218741a252700dbe&amp;source=constructor'
        },
        {
            route: 'Пешком, от платформы Косино',
            description:
                'Последний вагон из центра, по переходу налево. Проехать до остановки «Косинская фабрика» на автобусах 773, 79 или 723.',
            mapLink:
                'https://yandex.ru/map-widget/v1/?um=constructor%3Af8f849ecf73b255950bb675ce387ad3ab1eeeefa2afaa8b3fdbfa0ed7e8cc707&amp;source=constructor'
        },
        {
            route: 'Пешком, от метро Лермонтовский проспект',
            description:
                'Последний вагон из центра, по переходу налево. Проехать до остановки «Косинская фабрика» на автобусах 773, 79 или 723.',
            mapLink:
                'https://yandex.ru/map-widget/v1/?um=constructor%3Ab39424709f8d16c3cd8f2b4f5ad6845a75a7740484c54085189e04688a46eee5&amp;source=constructor'
        }
    ]
};
