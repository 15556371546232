import { AvailableTypes, OptionApiSource } from '@/components/editor/config/types';
import { Permissions } from '@/components/editor/config/acl/pages';

export default {
    default: {
        options: {
            title: {
                type: AvailableTypes.string,
                title: 'Заголовок',
                translitTo: 'slug',
                req: true
            },
            slug: {
                type: AvailableTypes.string,
                title: 'Символьный код страницы',
                req: true
            },
            group: {
                type: AvailableTypes.select,
                title: 'Группа',
                values: {
                    type: AvailableTypes.apiSource,
                    path: '/groups/',
                    method: 'get',
                    conditions: {},
                    mappings: {
                        value: '_id',
                        text: 'options.name'
                    }
                }
            },
            users: {
                type: AvailableTypes.select,
                multiple: true,
                title: 'Пользователи',
                values: {
                    type: AvailableTypes.apiSource,
                    path: '/users/',
                    method: 'get',
                    conditions: {},
                    acl: permissions => {
                        return (
                            permissions.includes(Permissions.ADMIN) ||
                            permissions.includes(Permissions.USERS_READ)
                        );
                    },
                    mappings: {
                        value: '_id',
                        text: 'name'
                    }
                } as OptionApiSource
            },
            breadcrumbTitle: {
                type: AvailableTypes.textarea,
                title: 'Название в хлебных крошках'
            },
            tags: {
                title: 'теги',
                type: AvailableTypes.arrayOfType,
                item: {
                    type: AvailableTypes.tag
                }
            },
            blogTitle: {
                title: 'Заголовок для блога',
                type: AvailableTypes.string
            },
            blogDescription: {
                title: 'Описание для блога',
                type: AvailableTypes.string
            },
            pageImg: {
                title: 'Изображение',
                type: AvailableTypes.image
            },
            meta: {
                type: AvailableTypes.object,
                title: 'Мета',
                item: {
                    title: {
                        title: 'title',
                        type: AvailableTypes.string
                    },
                    description: {
                        title: 'description',
                        type: AvailableTypes.string
                    },
                    og: {
                        title: 'OpenGraph',
                        type: AvailableTypes.object,
                        item: {
                            title: {
                                title: 'Название объекта',
                                type: AvailableTypes.string
                            },
                            'type ': {
                                title: 'Тип',
                                type: AvailableTypes.string
                            },
                            image: {
                                title: 'Изображение',
                                type: AvailableTypes.image
                            },
                            url: {
                                title: 'Канонический URL-адрес',
                                type: AvailableTypes.string
                            }
                        }
                    }
                }
            },
            palette: {
                type: AvailableTypes.palette,
                title: 'Палитра',
                req: true
            },
            pageType: {
                type: AvailableTypes.select,
                title: 'Тип страницы',
                values: [
                    {
                        text: 'Продуктовая страница',
                        value: 'product',
                        selected: true
                    },
                    {
                        text: 'Блог',
                        value: 'blog'
                    }
                ],
                req: true
            },
            notAddToSitemap: {
                type: AvailableTypes.boolean,
                title: 'Не включать в карту сайта',
                default: true
            },
            buildUrlFromRoot: {
                type: AvailableTypes.boolean,
                title: 'Строить url от корня'
            },
            showInMenu: {
                type: AvailableTypes.boolean,
                title: 'Показывать в меню'
            }
        }
    }
};
