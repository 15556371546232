import { Mark } from 'tiptap';
import { toggleMark } from 'tiptap-commands';

export default class Bold extends Mark {
    get name() {
        return 'bgPalette';
    }

    get schema() {
        return {
            parseDOM: [
                {
                    tag: 'span.palette-background-bg'
                }
            ],
            toDOM: () => {
                return ['span', { class: 'palette-background-bg' }, 0];
            }
        };
    }

    commands({ type }) {
        return () => toggleMark(type);
    }
}
