import { AvailableTypes } from '@/components/editor/config/types';

export const analyticsOptions = {
    ga: {
        type: AvailableTypes.string,
        title: 'GOOGLE ANALYTICS'
    },
    gtm: {
        type: AvailableTypes.string,
        title: 'GOOGLE TAG MANAGER ID'
    },
    ya: {
        type: AvailableTypes.string,
        title: 'ЯНДЕКС.МЕТРИКА'
    },
    fb: {
        type: AvailableTypes.string,
        title: 'FACEBOOK PIXEL'
    },
    vk: {
        type: AvailableTypes.string,
        title: 'VK PIXEL'
    }
};
