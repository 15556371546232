
    import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
    import { namespace } from 'nuxt-property-decorator';
    import OptionsFieldsEditor from '@/components/editor/options/fields/OptionsFieldsEditor.vue';

    const editor = namespace('editor');

    @Component({
        components: { OptionsFieldsEditor }
    })
    export default class OptionsFieldsInlineEditor extends Vue {
        @Prop() options;
        @Prop() component;
        @Prop({ default: '' }) value!: any;
        @Prop() keyOption;
        @editor.Mutation setOptionValue;

        val = this.value;
        selfChange = false;

        editor = null;

        getPageStore() {
            if (this.$store.state['editor'].currentPage) {
                return 'editor';
            } else {
                return 'page';
            }
        }

        @Watch('value')
        setVal(val, oldVal) {
            if (!this.selfChange) {
                this.val = val;
            } else {
                this.selfChange = false;
            }
        }

        get testParam() {
            return this.$route.query.test;
        }

        onChangeValue(val) {
            if (val !== undefined) {
                this.selfChange = true;
                this.setOptionValue({
                    entity: this.component,
                    key: this.keyOption,
                    value: val
                });
            }
        }

        get replacedValue() {
            if (typeof this.value !== 'undefined') {
                return this.replaceAll(this.value, '<p></p>', '<br>');
            }
            return '';
        }

        replaceAll(str, find, replace) {
            return str.replace(new RegExp(find, 'g'), replace);
        }
    }
