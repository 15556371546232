import { Link } from 'tiptap-extensions';
import { updateMark, removeMark } from 'tiptap-commands';

export default class CustomizationLink extends Link {
    get defaultOptions() {
        return {
            openOnClick: true
        };
    }

    get schema() {
        return {
            attrs: {
                href: {
                    default: null
                },
                rel: {
                    default: 'noopener noreferrer nofollow'
                },
                target: {
                    default: '_self'
                }
            },
            parseDOM: [
                {
                    tag: 'a[href]',
                    getAttrs: dom => ({
                        href: dom.getAttribute('href'),
                        rel: dom.getAttribute('rel'),
                        target: dom.getAttribute('target')
                    })
                }
            ],
            toDOM: node => [
                'a',
                {
                    ...node.attrs
                },
                0
            ]
        };
    }

    commands({ type }) {
        return attrs => {
            if (attrs.href || attrs.rel || attrs.target) {
                return updateMark(type, attrs);
            }
            return removeMark(type);
        };
    }
}
