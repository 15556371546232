import { AvailableTypes } from '@/components/editor/config/types';

export const siteOptions = {
    slug: {
        type: AvailableTypes.string,
        title: 'Псевдоним'
    },
    name: {
        type: AvailableTypes.string,
        title: 'Имя'
    },
    showInMenu: {
        type: AvailableTypes.checkbox,
        title: 'Отображать в меню'
    }
};
