import { Mark } from 'tiptap';
import { updateMark, removeMark } from 'tiptap-commands';

export default class Color extends Mark {
    get name() {
        return 'color';
    }

    get schema() {
        return {
            attrs: {
                color: {
                    default: '#141415'
                }
            },
            parseDOM: [
                {
                    style: 'color',
                    getAttrs: mark => ({ color: mark })
                }
            ],
            toDOM: ({ attrs: { color } }) => [
                'span',
                {
                    style: `color: ${color}`
                },
                0
            ]
        };
    }

    commands({ type }) {
        return attrs => {
            if (attrs.color && attrs.color !== '#141415') {
                return updateMark(type, attrs);
            }
            return removeMark(type);
        };
    }
}
