import { Mark } from 'tiptap';
import { updateMark, removeMark } from 'tiptap-commands';

export default class LineHeight extends Mark {
    get name() {
        return 'lineHeight';
    }

    get schema() {
        return {
            attrs: {
                lineHeight: {
                    default: '1.5'
                }
            },
            parseDOM: [
                {
                    style: 'line-height',
                    getAttrs: mark => ({ lineHeight: mark })
                }
            ],
            toDOM: ({ attrs: { lineHeight } }) => [
                'span',
                {
                    style: `line-height: ${lineHeight}`
                },
                0
            ]
        };
    }

    commands({ type }) {
        return attrs => {
            if (attrs.lineHeight && attrs.lineHeight !== '1.5') {
                return updateMark(type, attrs);
            }
            return removeMark(type);
        };
    }
}
