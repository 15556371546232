import types, { AvailableTypes, ComponentMeta } from '@/components/editor/config/types';
import { getComponentMeta } from '@/store/editor';

let pageStore = 'page';

let componentName;

export const makeObject = (optionItem, noKey = false) => {
    let newObject: any = {};
    for (let key in optionItem) {
        if (optionItem.hasOwnProperty(key)) {
            let item = optionItem[key];
            let defaultValue;
            if (typeof optionItem === 'object' && optionItem.default) {
                defaultValue = optionItem['default'];
            } else if (typeof item === 'object' && item.default) {
                defaultValue = item['default'];
            }
            let type = item.type !== undefined ? item.type : item;
            if (item.type === undefined) {
                noKey = true;
            }
            switch (type) {
                case AvailableTypes.string:
                case AvailableTypes.checkbox:
                case AvailableTypes.componentsList:
                case AvailableTypes.fullComponentsList:
                case AvailableTypes.date:
                case AvailableTypes.editor:
                case AvailableTypes.number:
                    if (noKey) {
                        newObject = defaultValue ? defaultValue : '';
                    } else {
                        newObject[key] = defaultValue ? defaultValue : '';
                    }
                    break;
                case AvailableTypes.select:
                    const val = item.multiple ? [] : '';

                    if (noKey) {
                        newObject = defaultValue ? defaultValue : val;
                    } else {
                        newObject[key] = defaultValue ? defaultValue : val;
                    }
                    break;
                case AvailableTypes.json:
                    if (noKey) {
                        newObject = defaultValue ? defaultValue : {};
                    } else {
                        newObject[key] = defaultValue ? defaultValue : {};
                    }
                    break;
                case AvailableTypes.id:
                    if (noKey) {
                        newObject = makeid(32);
                    } else {
                        newObject[key] = makeid(32);
                    }
                    break;
                case AvailableTypes.boolean:
                    let defaultBool = defaultValue ? defaultValue : false;
                    if (noKey) {
                        newObject = defaultBool;
                    } else {
                        newObject[key] = defaultBool;
                    }
                    break;
                case AvailableTypes.array:
                    if (defaultValue) {
                        newObject[key] = defaultValue;
                    } else {
                        newObject[key] = [];
                        newObject[key].push(makeObject(item.item));
                    }
                    break;
                case AvailableTypes.arrayOfType:
                    if (defaultValue) {
                        newObject[key] = defaultValue;
                    } else {
                        newObject[key] = [];
                        newObject[key].push(makeObject(item.item, true));
                    }
                    break;
                case AvailableTypes.object:
                    newObject[key] = defaultValue ? defaultValue : makeObject(item.item);
                    break;
                case AvailableTypes.componentOptions:
                    let meta: ComponentMeta = getComponentMeta(
                        optionItem.componentName
                            ? optionItem.componentName
                            : componentName
                    );
                    if (meta) {
                        if (optionItem.componentName) {
                            newObject = makeObject(meta.options);
                        } else {
                            newObject[key] = makeObject(meta.options);
                        }
                    }
                    break;
                case AvailableTypes.component:
                    componentName = item.componentName;
                default:
                    if (types[AvailableTypes[type]]) {
                        let value = defaultValue
                            ? defaultValue
                            : makeObject(types[AvailableTypes[type]]);
                        if (noKey) {
                            newObject = value;
                        } else {
                            newObject[key] = value;
                        }
                    }
            }
        }
    }
    return newObject;
};

// TODO refactor part with componentImgs later
let componentImgs: object[] = [];

export const getComponentImages = () => {
    return componentImgs;
};

export const resetComponentImages = () => {
    componentImgs = [];
};

export const makeOptions = (optionItem, options, noKey = false) => {
    let newObject: any = {};
    for (let key in optionItem) {
        if (optionItem.hasOwnProperty(key)) {
            if (options === undefined || (options[key] === undefined && key !== 'type')) {
                continue;
            }
            let item = optionItem[key];
            let option = options[key] !== undefined ? options[key] : options;
            if (option === undefined) {
                continue;
            }
            let type = item.type !== undefined ? item.type : item;
            if (item.type === undefined) {
                noKey = true;
            }

            option = sanitizeValue(type, option);

            switch (type) {
                case AvailableTypes.date:
                case AvailableTypes.string:
                case AvailableTypes.code:
                case AvailableTypes.number:
                case AvailableTypes.checkbox:
                case AvailableTypes.editor:
                case AvailableTypes.select:
                case AvailableTypes.componentsList:
                case AvailableTypes.boolean:
                case AvailableTypes.id:
                case AvailableTypes.pagesMultiselect:
                case AvailableTypes.json:
                    if (noKey) {
                        newObject = option;
                    } else {
                        newObject[key] = option;
                    }
                    break;
                case AvailableTypes.array:
                    newObject[key] = [];
                    for (let k in option) {
                        newObject[key].push(makeOptions(item.item, option[k]));
                    }
                    break;
                case AvailableTypes.arrayOfType:
                    newObject[key] = [];
                    if (Array.isArray(option)) {
                        option.map(itemOpt => {
                            newObject[key].push(makeOptions(item.item, itemOpt, true));
                        });
                    }
                    break;
                case AvailableTypes.object:
                    newObject[key] = makeOptions(item.item, options[key]);
                    break;
                case AvailableTypes.componentOptions:
                    let meta: ComponentMeta = getComponentMeta(
                        optionItem.componentName
                            ? optionItem.componentName
                            : componentName
                    );
                    if (meta) {
                        if (optionItem.componentName) {
                            newObject = makeOptions(meta.options, options);
                        } else {
                            newObject[key] = makeOptions(meta.options, options[key]);
                        }
                    }
                    break;
                case AvailableTypes.additionalComponent:
                    meta = getComponentMeta(options[key].name);
                    if (meta) {
                        newObject[key] = {
                            ...options[key],
                            options: makeOptions(meta.options, options[key].options)
                        };
                    }
                    break;
                case AvailableTypes.fullComponentsList:
                    meta = getComponentMeta(options[key].name);
                    if (meta) {
                        newObject[key] = {
                            ...options[key],
                            options: makeOptions(meta.options, options[key].options)
                        };
                    }
                    break;
                case AvailableTypes.component:
                    componentName = item.componentName;
                default:
                    if (
                        type === AvailableTypes.image ||
                        type === AvailableTypes.galleryImage ||
                        type === AvailableTypes.file
                    ) {
                        if (option.src && option.src.indexOf('uploads') !== -1) {
                            componentImgs.push(option);
                        }
                    }
                    if (types[AvailableTypes[type]]) {
                        let optionsValue = options[key];
                        if (options[key] === undefined) {
                            optionsValue = options;
                        }
                        const value = makeOptions(
                            types[AvailableTypes[type]],
                            optionsValue
                        );
                        if (noKey) {
                            newObject = value;
                        } else {
                            newObject[key] = value;
                        }
                    }
            }
        }
    }

    return newObject;
};

export function sanitizeValue(type, value) {
    let newValue;
    switch (type) {
        case AvailableTypes.checkbox:
        case AvailableTypes.number:
        case AvailableTypes.editor:
            if (value === '<p></p>') {
                newValue = '';
            }
            newValue = value;
            break;
        case AvailableTypes.string:
            if (value === '<p></p>') {
                newValue = '';
            }
            newValue = value;
            break;
        case AvailableTypes.id:
            if (typeof value === 'string') {
                newValue = value;
            } else {
                newValue = '';
            }
            break;
        case AvailableTypes.boolean:
            if (typeof value === 'boolean' || value === 'true' || value === 'false') {
                newValue = value;
            } else {
                newValue = false;
            }
            break;
        case AvailableTypes.code:
            if (!value) {
                newValue = '';
            } else {
                newValue = value;
            }
            break;
        case AvailableTypes.select:
            if (
                typeof value === 'string' ||
                Array.isArray(value) ||
                value.toString().length > 0
            ) {
                newValue = value;
            } else {
                newValue = '';
            }
        default:
            newValue = value;
    }
    return newValue;
}

export function setPageStore(val) {
    pageStore = val;
}

export function getPageStore() {
    return pageStore;
}

export function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
