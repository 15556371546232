
    import { Component, Prop, mixins } from 'nuxt-property-decorator';
    import ImageComponent from '@/components/blocks/ImageComponent.vue';
    import SliderWrapper from '@/components/blocks/SliderWrapper.vue';
    import swipe from '@/directives/swipe';
    import ImageMixin from '@/mixins/imageMixin';

    @Component({
        components: { SliderWrapper, ImageComponent },
        directives: { swipe }
    })
    export default class ModelsImages extends mixins(ImageMixin) {
        @Prop() images;

        backView: boolean = false;
        sliderId = (Math.floor(Math.random() * (1000 - 1)) + 1).toString();

        toggleView(callback) {
            callback();
            this.backView = !this.backView;
        }

        isFirst(index): boolean {
            return index === 0;
        }
    }
