import { siteOptions } from '@/components/editor/config/settings/site';
import { analyticsOptions } from '@/components/editor/config/settings/analytics';
import { seoOptions } from '@/components/editor/config/settings/seo';

export const settings = {
    site: {
        title: 'Настройки сайта',
        options: siteOptions
    },
    seo: {
        title: 'Настройки seo',
        options: seoOptions
    },
    analytics: {
        title: 'Аналитика',
        options: analyticsOptions
    }
};
