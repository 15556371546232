export default {
    bind(el, binding) {
        let xDown = null;
        let yDown = null;

        const handleTouchStart = ({ touches, clientX, clientY }) => {
            xDown = touches ? touches[0].clientX : clientX;
            yDown = touches ? touches[0].clientY : clientY;
        };

        const handleTouchMove = ({ touches, clientX, clientY }) => {
            if (!xDown || !yDown) {
                return;
            }

            const xUp = touches ? touches[0].clientX : clientX;
            const yUp = touches ? touches[0].clientY : clientY;

            const xDiff = xDown - xUp;
            const yDiff = yDown - yUp;

            if (Math.abs(xDiff) > Math.abs(yDiff)) {
                if (xDiff > 0) {
                    binding.arg === 'left' && binding.value();
                } else {
                    binding.arg === 'right' && binding.value();
                }
            } else {
                if (yDiff > 0) {
                    binding.arg === 'up' && binding.value();
                } else {
                    binding.arg === 'down' && binding.value();
                }
            }

            xDown = null;
            yDown = null;
        };

        el.addEventListener('touchstart', handleTouchStart, false);
        el.addEventListener('touchmove', handleTouchMove, false);
        el.addEventListener('mousedown', handleTouchStart, false);
        el.addEventListener('mousemove', handleTouchMove, false);

        el._handleTouchStart = handleTouchStart;
        el._handleTouchMove = handleTouchMove;
    },
    unbind(el) {
        el.removeEventListener('touchstart', el._handleTouchStart, false);
        el.removeEventListener('touchmove', el._handleTouchMove, false);
        el.removeEventListener('mousedown', el._handleTouchStart, false);
        el.removeEventListener('mousemove', el._handleTouchMove, false);

        delete el._handleTouchStart;
        delete el._handleTouchMove;
    }
};
