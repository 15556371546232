export const Permissions = Object.freeze({
    ADMIN: 'admin',
    PAGES_WRITE: 'pages:write',
    PAGES_WRITE_OWN: 'pages:write-own',
    PAGES_SETTINGS: 'pages:settings',
    PAGES_READ: 'pages:read',
    USERS_READ: 'users:read'
});

export const acl = {
    '/admin/users': [Permissions.ADMIN],
    '/admin/users/:id': [Permissions.ADMIN],
    '/admin/settings': [Permissions.ADMIN],
    '/admin/settings/:name': [Permissions.ADMIN],
    '/admin/entities': [Permissions.ADMIN],
    '/admin/entities/:entity': [Permissions.ADMIN],
    '/admin/entities/:entity/:id': [Permissions.ADMIN],
    '/admin/forms': [Permissions.ADMIN],
    '/admin/forms/:id': [Permissions.ADMIN],
    '/admin/blockpresets': [Permissions.ADMIN],
    '/admin/blockpresets/:id': [Permissions.ADMIN],
    '/admin/groups': [Permissions.PAGES_WRITE],
    '/admin/groups/:id': [Permissions.PAGES_WRITE],
    '/admin/pages': [
        Permissions.PAGES_WRITE,
        Permissions.PAGES_READ,
        Permissions.PAGES_WRITE_OWN
    ],
    '/admin/pages/:id': [
        Permissions.PAGES_WRITE,
        Permissions.PAGES_SETTINGS,
        Permissions.PAGES_WRITE_OWN
    ],
    '/admin/pages/:id/components': [Permissions.PAGES_WRITE, Permissions.PAGES_WRITE_OWN],
    '/admin/modals': [Permissions.ADMIN],
    '/admin/modals/:id': [Permissions.ADMIN],
    '/admin/colors': [Permissions.ADMIN],
    '/admin/colors/:id': [Permissions.ADMIN],
    '/admin/redirects': [Permissions.ADMIN],
    '/admin/redirects/:id': [Permissions.ADMIN],
    '/admin/tags': [Permissions.ADMIN],
    '/admin/tags/:id': [Permissions.ADMIN]
};
