import { Mark } from 'tiptap';
import { updateMark, removeMark } from 'tiptap-commands';

export default class FontSize extends Mark {
    get name() {
        return 'fontSize';
    }

    get schema() {
        return {
            attrs: {
                fontSize: {
                    default: '16px'
                }
            },
            parseDOM: [
                {
                    style: 'font-size',
                    getAttrs: mark => ({ fontSize: mark })
                }
            ],
            toDOM: ({ attrs: { fontSize } }) => [
                'span',
                {
                    style: `font-size: ${
                        fontSize.indexOf('px') !== -1 ? fontSize : fontSize + 'px'
                    }`
                },
                0
            ]
        };
    }

    commands({ type }) {
        return attrs => {
            if (attrs.fontSize && attrs.fontSize !== '16') {
                return updateMark(type, attrs);
            }
            return removeMark(type);
        };
    }
}
