
    import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
    import EditorCommandWithInput from '@/components/editor/options/editor/EditorCommandWithInput.vue';
    import Button from '@/components/blocks/Button.vue';

    @Component({
        components: { Button, EditorCommandWithInput }
    })
    export default class OptionsFieldsEditorMenu extends Vue {
        @Prop() isActive;
        @Prop() commands;
        @Prop() getMarkAttrs;
        @Prop() inline;

        setNewVal(val, command) {
            command(val);
        }

        @Watch('linkMenuIsActive', { immediate: true })
        activeLinkMenu(newVal, oldVal) {
            this.$emit('input', newVal);
        }
    }
