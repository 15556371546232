
    import { mixins, Component, Prop } from 'nuxt-property-decorator';
    import accordionMixin from '@/mixins/accordionMixin';
    import WindowMixin from '@/mixins/windowMixin';

    @Component
    export default class QuestionCollapse extends mixins(accordionMixin, WindowMixin) {
        @Prop(String) question;
        @Prop(String) answer;
    }
